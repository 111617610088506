// Colors
$text-color: #6A798C;
$heading-color: #333B69;
$bg-light: #F7F9FA;
$bg-dark: #282e38;
$border-color: #E8ECED;
$border-color-dark: #313131;
$main-color: #FA6262;
$secondary-color: #4C86E7;
$meta-color: #6A798C;


// Fonts
$body-font: 'Roboto', sans-serif;
$heading-font: 'Roboto', sans-serif;


// Navbar
$nav-height: 72px;